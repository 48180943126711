<template>
  <div>
    <!-- 操作弹窗 -->
    <el-dialog title="OCR" :visible.sync="showCutting" fullscreen :close-on-press-escape="false" @close="hideCutting">
      <!-- 导入的试题数据 -->
      <div class="questionDoc" v-loading="generateLoading">
        <div class="leftview">
          <!-- 画板区域 -->
          <div class="blankDrawingBoard" v-if="testPaperImgBase64.length == 0">
            <div class="no-data--empty">
              <img src="@assets/images/no-data3.png" alt="">
              <p>暂无试卷，请上传图片</p>
            </div>
          </div>
          <div class="drawingBoard" ref="drawingBoard" v-if="testPaperImgBase64.length > 0" @mousedown="mousedownPaper"
            @touchstart="mousedownPaper" @mousemove="mousemovePaper" @mouseup="mouseupPaper" @mouseleave="mouseupPaper"
            @touchend="mouseupPaper" @touchmove="mousemovePaper" @wheel="handleScroll">
            <!-- 是否开启拖拽试卷 -->
            <el-tooltip effect="dark" :content="paperDrag ? '已开启拖拽试卷' : '已关闭拖拽试卷'" placement="top">
              <div class="movePaper" @click="movePaperClick">
                <span v-if="paperDrag" class="iconfont">&#xe68e;</span>
                <span v-else class="iconfont">&#xe68c;</span>
              </div>
            </el-tooltip>
            <!-- 试卷位置 -->
            <div class="testPaper"
              :class="[paperDrag ? 'all-scroll' : '', (!paperDrag && cuttingHeight > 0 && cuttingWidth > 0) ? 'stepBack' : '']"
              :style="'background-image: url(' + testPaperImgBase64 + ');background-position:' + paperOffsetLeft + 'px ' + paperOffsetTop + 'px;background-size:' + imageWidth + 'px auto;'">
            </div>
            <!-- 拖拽框 -->
            <div class="dragFrame" v-if="!paperDrag && cuttingHeight > 0 && cuttingWidth > 0" id="captureArea"
              :style="'top: ' + cuttingTop + 'px;left: ' + cuttingLeft + 'px;width: ' + cuttingWidth + 'px;height: ' + cuttingHeight + 'px;background-image: url(' + testPaperImgBase64 + ');background-position:' + getdragFrameBackLeft + 'px ' + getdragFrameBackTop + 'px;background-size:' + imageWidth + 'px auto;'">
              <!-- 工具栏 -->
              <div class="operating" :style="operatingStyle" v-if="!open_move_use">
                <div class="independent">
                  <el-tooltip effect="dark" content="题目识别" placement="bottom">
                    <div div class="iconfont introduce" :class="isblank ? '' : 'disabled'">&#xe692;</div>
                  </el-tooltip>
                  <div class="topicon" v-if="isblank">
                    <el-tooltip effect="dark" content="追加" placement="top">
                      <div class="iconfont" @click="capture(1,1)">&#xe741;</div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="替换" placement="top">
                      <div class="iconfont" @click="capture(1,2)">&#xe6ec;</div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="independent">
                  <el-tooltip effect="dark" content="试卷切题识别" placement="bottom">
                    <div div class="iconfont introduce" :class="isblank ? '' : 'disabled'">&#xe693;</div>
                  </el-tooltip>
                  <div class="topicon" v-if="isblank">
                    <el-tooltip effect="dark" content="追加" placement="top">
                      <div class="iconfont" @click="capture(2,1)">&#xe741;</div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="替换" placement="top">
                      <div class="iconfont" @click="capture(2,2)">&#xe6ec;</div>
                    </el-tooltip>
                  </div>
                </div>
                <!-- <div class="independent">
                  <el-tooltip effect="dark" content="精细版结构化识别" placement="bottom">
                    <div class="iconfont introduce" :class="isblank ? 'disabled' : ''" @click="isblank ? '' : capture(3,2)">&#xe698;</div>
                  </el-tooltip>
                </div> -->
                <div class="independent">
                  <el-tooltip effect="dark" content="公式识别" placement="bottom">
                    <div div class="iconfont introduce" :class="isblank ? '' : 'disabled'">&#xe691;</div>
                  </el-tooltip>
                  <div class="topicon" v-if="isblank">
                    <el-tooltip effect="dark" content="追加" placement="top">
                      <div class="iconfont" @click="capture(4,1)">&#xe741;</div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="替换" placement="top">
                      <div class="iconfont" @click="capture(4,2)">&#xe6ec;</div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="independent">
                  <el-tooltip effect="dark" content="整页试卷识别" placement="bottom">
                    <div div class="iconfont introduce" :class="isblank ? '' : 'disabled'">&#xe698;</div>
                  </el-tooltip>
                  <div class="topicon" v-if="isblank">
                    <el-tooltip effect="dark" content="追加" placement="top">
                      <div class="iconfont" @click="capture(5,1)">&#xe741;</div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="替换" placement="top">
                      <div class="iconfont" @click="capture(5,2)">&#xe6ec;</div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="independent">
                  <el-tooltip effect="dark" content="使用图片" placement="bottom">
                    <div div class="iconfont introduce" :class="isblank ? '' : 'disabled'">&#xe690;</div>
                  </el-tooltip>
                  <div class="topicon" v-if="isblank">
                    <el-tooltip effect="dark" content="追加" placement="top">
                      <div class="iconfont" @click="capture(6,1)">&#xe741;</div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="替换" placement="top">
                      <div class="iconfont" @click="capture(6,2)">&#xe6ec;</div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="independent">
                  <el-tooltip effect="dark" content="取消" placement="bottom">
                    <div class="iconfont introduce" @click="capture(7,1)">&#xe605;</div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 所有试题 -->
        <div class="allquestion">
          <div class="option">
            <el-button v-show="!openCheck && checkList.length > 0" type="custom_primary" size="mini" :disabled="optIndex == 0"
              @click="setQuestionsIndex(optIndex - 1)">上一个</el-button>
            <el-button v-show="!openCheck && checkList.length > 0" type="custom_primary" size="mini"
              :disabled="optIndex == checkList.length - 1" @click="setQuestionsIndex(optIndex + 1)">下一个</el-button>
            <el-upload v-if="!openCheck" :auto-upload="false" class="upimg" action="" :limit="1" :show-file-list="false"
              ref="myUpload" :accept="$upload.imgAccept" :on-change="uploadPaper">
              <el-tooltip class="item" effect="dark" content="上传格式为：.jpg,.jpeg,.png。请注意图片大小不超过10M，最长边不超过8192像素！"
                placement="bottom">
                <el-button type="primary" size="mini">上传图片</el-button>
              </el-tooltip>
            </el-upload>
            <el-button v-if="!openCheck && questionDoc.length > 0" type="custom_primary" size="mini"
              @click="checkAdjust">勾选</el-button>
            <el-button v-if="checkList.length > 0" type="custom_primary" size="mini"
              @click="cancelAdjust">清除勾选</el-button>
            <el-button v-if="openCheck" type="custom_primary" size="mini" @click="submitCheck">确认勾选</el-button>
            <!-- <el-button v-if="!openCheck" type="custom_info" size="mini" @click="hideCutting">取 消</el-button> -->
            <el-button v-if="!openCheck && questionDoc.length > 0" type="custom_primary" size="mini" @click="submitProblem"
              :disabled="btnload || pageloadingall" v-loading="btnload || pageloadingall">提 交</el-button>
            <el-tooltip class="item" v-if="questionDoc.length > 0" effect="dark" :content="checkTopic ? '设为编辑模式' : '设为查阅模式'"
              placement="bottom">
              <el-button circle type="primary" @click="checkTopic = !checkTopic"
                :icon="checkTopic ? 'el-icon-edit-outline' : 'el-icon-search'"></el-button>
            </el-tooltip>
            <el-tooltip style="margin-left: 10px;" v-if="questionDoc.length > 0" :content="location == 1 ? '关闭智能定位' : '开启智能定位'"
              placement="bottom">
              <el-switch v-model="location" active-color="#b3b3b3" inactive-color="#6340c8" active-value="0"
                inactive-value="1"></el-switch>
            </el-tooltip>
          </div>
          <div class="list" id="lists">
            <div class="no-data--empty" v-if="$isEmpty(questionDoc)">
              <img src="@assets/images/no-data3.png" alt="">
              <p>暂无试题，请上传或添加~</p>
              <div class="addOperation">
                <el-button type="custom_primary" size="small" icon="el-icon-plus"
                  @click="addOperation(0)">添加试题</el-button>
              </div>
            </div>
            <div v-for="(item, index) in questionDoc" :key="index">
              <div class="addOperation" v-if="index == 0 && !openCheck">
                <el-button type="custom_primary" size="small" icon="el-icon-plus"
                  @click="addOperation(0)">添加试题</el-button>
              </div>
              <div class="item" :id="'chenc-' + index"
                :class="[isCheck(index) ? 'isCheck' : '', index == checkList[optIndex] ? 'optCheck' : '']">
                <div class="headline">
                  <!-- <div class="iconfont iconcheckbox" v-if="openCheck" @click="isCheckChange(index)">{{
                    isCheck(index) ?'&#xe696;':'&#xe79e;' }}</div> -->
                  <div class="name">第{{ index + 1 }}题<span class="bold">{{ item.syque_typ_id !=
                    14 ? item.syque_typ_name:'组合题'}}</span></div>
                  <div class="delete" v-if="checkList.length == 0 && !openCheck"><i class="el-icon-delete"
                      @click="deleteQuestionDocItem(index)"></i></div>
                </div>
                <div class="realm">
                  <span class="xing">*</span>
                  题目
                </div>
                <div class="topic" :id="'chenc-' + index + '-title'"
                  :class="[isCheck(index + '-title') ? 'isCheck' : '', (index + '-title') == checkList[optIndex] ? 'optCheck' : '']">
                  <div class="iconfont iconcheckbox" v-if="openCheck" @click="isCheckChange(index + '-title')">{{
                    isCheck(index + '-title') ?'&#xe696;':'&#xe79e;' }}</div>
                  <div v-if="checkTopic" class="richinline" v-html="item.tetea_pra_title"></div>
                  <try-editor v-else class="tinymce" :key="'editor_chenc-' + index + '-title'" v-model="item.tetea_pra_title"></try-editor>
                </div>
                <!-- 组合题 -->
                <template v-if="item.syque_typ_id == 14">
                  <div class="child">
                    <div class="addOperation" v-if="!openCheck && item.option_arr.length == 0">
                      <el-button type="custom_primary" size="small" icon="el-icon-plus"
                        @click="addOperation(index + '-0')">添加试题</el-button>
                    </div>
                    <div v-for="(item1, index1) in item.option_arr" :key="index1">
                      <div class="addOperation" v-if="index1 == 0 && !openCheck">
                        <el-button type="custom_primary" size="small" icon="el-icon-plus"
                          @click="addOperation(index + '-0')">添加试题</el-button>
                      </div>
                      <div class="item" :id="'chenc-' + index + '-' + index1"
                        :class="[isCheck(index + '-' + index1) ? 'isCheck' : '', (index + '-' + index1) == checkList[optIndex] ? 'optCheck' : '']">
                        <div class="headline">
                          <!-- <div class="iconfont iconcheckbox" v-if="openCheck"
                            @click="isCheckChange(index + '-' + index1)">{{ isCheck(index + '-' +
                              index1)?'&#xe696;':'&#xe79e;' }}</div> -->
                          <div class="name">第{{ index1 + 1 }}小题<span class="bold">{{ item1.syque_typ_name }}</span>
                          </div>
                          <div class="delete" v-if="checkList.length == 0 && !openCheck"><i class="el-icon-delete"
                              @click="deleteQuestionDocItem(index + '-' + index1)"></i>
                          </div>
                        </div>
                        <div class="realm">
                          <span class="xing">*</span>
                          题目
                        </div>
                        <div class="topic" :id="'chenc-' + index + '-' + index1 + '-title'"
                          :class="[isCheck(index + '-' + index1 + '-title') ? 'isCheck' : '', (index + '-' + index1 + '-title') == checkList[optIndex] ? 'optCheck' : '']">
                          <div class="iconfont iconcheckbox" v-if="openCheck"
                            @click="isCheckChange(index + '-' + index1 + '-title')">{{ isCheck(index + '-' + index1 +
                              '-title')?'&#xe696;':'&#xe79e;' }}</div>
                          <div v-if="checkTopic" class="richinline" v-html="item1.tetea_pra_title"></div>
                          <try-editor :key="'editor_chenc-' + index + '-' + index1 + '-title'" v-else class="tinymce" v-model="item1.tetea_pra_title"></try-editor>
                        </div>
                        <template v-if="item1.syque_typ_id == 2">
                          <!-- 多选题目 -->
                          <div class="realm">
                            <span class="xing">*</span>
                            选项
                            <span class="title">勾选正确答案，多选题请勾选多个选项</span>
                            <div class="addoptin" v-if="!openCheck" @click="addDocQuestion(item1, index)">
                              <i class="el-icon-circle-plus-outline"></i>添加选项
                            </div>
                          </div>
                          <ul class="answers">
                            <li v-for="(item2, index2) in item1.question_item" :key="index2"
                              :id="'chenc-' + index + '-' + index1 + '-' + index2 + '-value'"
                              :class="[isCheck(index + '-' + index1 + '-' + index2 + '-value') ? 'isCheck' : '', (index + '-' + index1 + '-' + index2 + '-value') == checkList[optIndex] ? 'optCheck' : '']">
                              <div class="iconfont iconcheckbox" v-if="openCheck"
                                @click="isCheckChange(index + '-' + index1 + '-' + index2 + '-value')">{{ isCheck(index
                                  + '-' + index1 + '-' + index2 + '-value')?'&#xe696;':'&#xe79e;' }}</div>
                              <el-checkbox v-model="item2.tetea_pra_ite_isanswer" :true-label="10"
                                :false-label="20"></el-checkbox>
                              <div class="index">{{ item2.teque_ite_code }}.</div>
                              <div v-if="checkTopic" class="richflex" v-html="item2.tetea_pra_ite_title"></div>
                              <try-editor :key="'editor_chenc-' + index + '-' + index1 + '-' + index2 + '-value'" v-else class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editor>
                              <i v-if="checkList.length == 0 && !openCheck" class="el-icon-delete"
                                @click="deleteDocQuestionItem(item1, index2)"></i>
                            </li>
                          </ul>
                        </template>
                        <template v-if="item1.syque_typ_id == 1 || item1.syque_typ_id == 5">
                          <!-- 单选题 -->
                          <div class="realm">
                            <span class="xing">*</span>
                            选项
                            <span class="title">勾选正确答案</span>
                            <div class="addoptin" v-if="!openCheck" @click="addDocQuestion(item1, index)">
                              <i class="el-icon-circle-plus-outline"></i>添加选项
                            </div>
                          </div>
                          <ul class="answers">
                            <li v-for="(item2, index2) in item1.question_item" :key="index2"
                              :id="'chenc-' + index + '-' + index1 + '-' + index2 + '-value'"
                              :class="[isCheck(index + '-' + index1 + '-' + index2 + '-value') ? 'isCheck' : '', (index + '-' + index1 + '-' + index2 + '-value') == checkList[optIndex] ? 'optCheck' : '']">
                              <div class="iconfont iconcheckbox" v-if="openCheck"
                                @click="isCheckChange(index + '-' + index1 + '-' + index2 + '-value')">{{ isCheck(index
                                  + '-' + index1 + '-' + index2 + '-value')?'&#xe696;':'&#xe79e;' }}</div>
                              <el-radio v-model="item1.answer" :label="item2.teque_ite_code">
                                <span></span>
                              </el-radio>
                              <div class="index">{{ item2.teque_ite_code }}.</div>
                              <div v-if="checkTopic" class="richflex" v-html="item2.tetea_pra_ite_title"></div>
                              <try-editor :key="'editor_chenc-' + index + '-' + index1 + '-' + index2 + '-value'" v-else class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editor>
                              <i v-if="checkList.length == 0 && !openCheck" class="el-icon-delete"
                                @click="deleteDocQuestionItem(item1, index2)"></i>
                            </li>
                          </ul>
                        </template>
                        <template
                          v-if="item1.syque_typ_id == 3 || item1.syque_typ_id == 4 || item1.syque_typ_id == 7 || item1.syque_typ_id == 8">
                          <!-- 填空题 -->
                          <div class="realm">
                            <span class="xing">*</span>
                            答案
                            <div class="addoptin" @click="addDocQuestion(item1, index)" v-if="item1.syque_typ_id != 7">
                              <i class="el-icon-circle-plus-outline"></i>添加答案
                            </div>
                          </div>
                          <ul class="answers">
                            <li v-for="(item2, index2) in item1.question_item" :key="index2"
                              :id="'chenc-' + index + '-' + index1 + '-' + index2 + '-value'"
                              :class="[isCheck(index + '-' + index1 + '-' + index2 + '-value') ? 'isCheck' : '', (index + '-' + index1 + '-' + index2 + '-value') == checkList[optIndex] ? 'optCheck' : '']">
                              <div class="iconfont iconcheckbox" v-if="openCheck"
                                @click="isCheckChange(index + '-' + index1 + '-' + index2 + '-value')">{{ isCheck(index
                                  + '-' + index1 + '-' + index2 + '-value')?'&#xe696;':'&#xe79e;' }}</div>
                              <div class="title" v-if="item1.syque_typ_id != 7">第{{ index2 + 1 }}空</div>
                              <div v-if="checkTopic" class="richflex" v-html="item2.tetea_pra_ite_title"></div>
                              <try-editor :key="'editor_chenc-' + index + '-' + index1 + '-' + index2 + '-answers'" v-else class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editor>
                              <i v-if="checkList.length == 0 && !openCheck" class="el-icon-delete"
                                @click="deleteDocQuestionItem(item1, index2)"></i>
                            </li>
                          </ul>
                        </template>
                        <div class="realm">
                          解析
                        </div>
                        <div class="topic">
                          <div v-if="checkTopic" class="richflex" v-html="item1.tetea_pra_analysis"></div>
                          <try-editor :key="'editor_chenc-analysis' + index + '-' + index1" v-else class="tinymce" v-model="item1.tetea_pra_analysis"></try-editor>
                        </div>
                        <div class="realm">
                          困难度
                        </div>
                        <div class="difficulty">
                          <el-radio v-model="item1.tetea_pra_difficulty" :label="10">简单</el-radio>
                          <el-radio v-model="item1.tetea_pra_difficulty" :label="20">普通</el-radio>
                          <el-radio v-model="item1.tetea_pra_difficulty" :label="30">困难</el-radio>
                        </div>
                        <div class="realm">
                          {{prepareKnowledgeType==1?'绑定知识点':'绑定章节'}}
                        </div>
                        <el-select v-if="prepareKnowledgeType==1" class="select" v-model="item1.tetea_kno_id" placeholder="请选择">
                          <el-option v-for="prepare in prepareKnowledge" :key="prepare[valuekey]"
                            :label="prepare[labelkey]" :value="prepare[valuekey]" />
                        </el-select>
                        <el-cascader v-else v-model="item1.tetea_kno_id" class="select" :options="prepareKnowledge" :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
                      </div>
                      <div class="addOperation" v-if="!openCheck">
                        <el-button type="custom_primary" size="small" icon="el-icon-plus"
                          @click="addOperation(index + '-' + (index1 + 1))">添加试题</el-button>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="item.syque_typ_id == 2">
                  <!-- 多选题目 -->
                  <div class="realm">
                    <span class="xing">*</span>
                    选项
                    <span class="title">勾选正确答案，多选题请勾选多个选项</span>
                    <div class="addoptin" v-if="!openCheck" @click="addDocQuestion(item, index)">
                      <i class="el-icon-circle-plus-outline"></i>添加选项
                    </div>
                  </div>
                  <ul class="answers">
                    <li v-for="(item2, index2) in item.question_item" :key="index2"
                      :id="'chenc-' + index + '-' + index2 + '-value'"
                      :class="[isCheck(index + '-' + index2 + '-value') ? 'isCheck' : '', (index + '-' + index2 + '-value') == checkList[optIndex] ? 'optCheck' : '']">
                      <div class="iconfont iconcheckbox" v-if="openCheck"
                        @click="isCheckChange(index + '-' + index2 + '-value')">{{
                          isCheck(index + '-' + index2 + '-value') ?'&#xe696;':'&#xe79e;' }}</div>
                      <el-checkbox v-model="item2.tetea_pra_ite_isanswer" :true-label="10"
                        :false-label="20"></el-checkbox>
                      <div class="index">{{ item2.teque_ite_code }}.</div>
                      <div v-if="checkTopic" class="richflex" v-html="item2.tetea_pra_ite_title"></div>
                      <try-editor :key="'editor_chenc-' + index + '-' + index2 + '-value'" v-else class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editor>
                      <i v-if="checkList.length == 0 && !openCheck" class="el-icon-delete"
                        @click="deleteDocQuestionItem(item, index2)"></i>
                    </li>
                  </ul>
                </template>
                <template v-if="item.syque_typ_id == 1 || item.syque_typ_id == 5">
                  <!-- 单选题 -->
                  <div class="realm">
                    <span class="xing">*</span>
                    选项
                    <span class="title">勾选正确答案</span>
                    <div class="addoptin" v-if="!openCheck" @click="addDocQuestion(item, index)">
                      <i class="el-icon-circle-plus-outline"></i>添加选项
                    </div>
                  </div>
                  <ul class="answers">
                    <li v-for="(item2, index2) in item.question_item" :key="index2"
                      :id="'chenc-' + index + '-' + index2 + '-value'"
                      :class="[isCheck(index + '-' + index2 + '-value') ? 'isCheck' : '', (index + '-' + index2 + '-value') == checkList[optIndex] ? 'optCheck' : '']">
                      <div class="iconfont iconcheckbox" v-if="openCheck"
                        @click="isCheckChange(index + '-' + index2 + '-value')">{{
                          isCheck(index + '-' + index2 + '-value') ?'&#xe696;':'&#xe79e;' }}</div>
                      <el-radio v-model="item.answer" :label="item2.teque_ite_code">
                        <span></span>
                      </el-radio>
                      <div class="index">{{ item2.teque_ite_code }}.</div>
                      <div v-if="checkTopic" class="richflex" v-html="item2.tetea_pra_ite_title"></div>
                      <try-editor v-else class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editor>
                      <i v-if="checkList.length == 0 && !openCheck" class="el-icon-delete"
                        @click="deleteDocQuestionItem(item, index2)"></i>
                    </li>
                  </ul>
                </template>
                <template v-if="item.syque_typ_id == 3 || item.syque_typ_id == 4 || item.syque_typ_id == 7 || item.syque_typ_id == 8">
                  <!-- 填空题 -->
                  <div class="realm">
                    <span class="xing">*</span>
                    答案
                    <div class="addoptin" @click="addDocQuestion(item, index)" v-if="item.syque_typ_id != 7">
                      <i class="el-icon-circle-plus-outline"></i>添加答案
                    </div>
                  </div>
                  <ul class="answers">
                    <li v-for="(item2, index2) in item.question_item" :key="index2"
                      :id="'chenc-' + index + '-' + index2 + '-value'"
                      :class="[isCheck(index + '-' + index2 + '-value') ? 'isCheck' : '', (index + '-' + index2 + '-value') == checkList[optIndex] ? 'optCheck' : '']">
                      <div class="iconfont iconcheckbox" v-if="openCheck"
                        @click="isCheckChange(index + '-' + index2 + '-value')">{{
                          isCheck(index + '-' + index2 + '-value') ?'&#xe696;':'&#xe79e;' }}</div>
                      <div class="title" v-if="item.syque_typ_id != 7">第{{ index2 + 1 }}空</div>
                      <div v-if="checkTopic" class="richflex" v-html="item2.tetea_pra_ite_title"></div>
                      <try-editor :key="'editor_chenc-' + index + '-' + index2 + '-answers'" v-else class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editor>
                      <i v-if="checkList.length == 0 && !openCheck" class="el-icon-delete"
                        @click="deleteDocQuestionItem(item, index2)"></i>
                    </li>
                  </ul>
                </template>
                <template v-if="item.syque_typ_id != 14">
                  <div class="realm">
                    解析
                  </div>
                  <div class="topic">
                    <div v-if="checkTopic" class="richflex" v-html="item.tetea_pra_analysis"></div>
                    <try-editor :key="'editor_chenc-' + index  + '-topic'" v-else class="tinymce" v-model="item.tetea_pra_analysis"></try-editor>
                  </div>
                  <div class="realm">
                    困难度
                  </div>
                  <div class="difficulty">
                    <el-radio v-model="item.tetea_pra_difficulty" :label="10">简单</el-radio>
                    <el-radio v-model="item.tetea_pra_difficulty" :label="20">普通</el-radio>
                    <el-radio v-model="item.tetea_pra_difficulty" :label="30">困难</el-radio>
                  </div>
                  <div class="realm">
                    {{prepareKnowledgeType==1?'绑定知识点':'绑定章节'}}
                  </div>
                  <el-select v-if="prepareKnowledgeType==1" class="select" v-model="item.tetea_kno_id" placeholder="请选择">
                    <el-option v-for="prepare in prepareKnowledge" :key="prepare[valuekey]"
                      :label="prepare[labelkey]" :value="prepare[valuekey]" />
                  </el-select>
                  <el-cascader v-else v-model="item.tetea_kno_id" class="select" :options="prepareKnowledge" :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
                </template>
              </div>
              <div class="addOperation" v-if="!openCheck">
                <el-button type="custom_primary" size="small" icon="el-icon-plus"
                  @click="addOperation(index + 1)">添加试题</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新建试题弹窗 -->
      <el-dialog title="新建试题" :visible.sync="questionDialog" width="1000px" :show-close="false">
        <div class="create-question">
          <div class="create-form-wrapper">
            <el-form ref="questionForm" :model="questionForm" label-width="82px">
              <el-form-item label="题干">
                <div class="form-item">
                  <el-form-item prop="tetea_pra_title">
                    <try-editor v-model="questionForm.tetea_pra_title" id="tetea_pra_title"></try-editor>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item label="是否组合题">
                <el-radio-group v-model="questionForm.tetea_pra_ismakeup" @input="checkIsCompose">
                  <el-radio :label="20">否</el-radio>
                  <el-radio :label="10">是</el-radio>
                </el-radio-group>
                <!-- 组合题添加小题 -->
                <el-button v-if="questionForm.tetea_pra_ismakeup == 10" type="custom_primary" size="medium"
                  @click="appendQues">
                  + 继续加题
                </el-button>
              </el-form-item>
              <div
                :class="{ 'compose-group': questionForm.tetea_pra_ismakeup == 10, 'question-data': questionForm.tetea_pra_ismakeup == 20 }"
                v-for="(que, que_index) in questionForm.option_arr" :key="que_index">
                <!-- 组合题 -->
                <div v-if="questionForm.tetea_pra_ismakeup == 10">
                  <el-form-item label="小题干">
                    <div class="form-item">
                      <el-form-item :prop="'option_arr.' + que_index + '.tetea_pra_title'">
                        <try-editor v-model="que.tetea_pra_title"></try-editor>
                      </el-form-item>
                      <!-- 删除选项 -->
                      <el-button class="iconfont mini" type="custom_primary" circle @click="removeQues(que_index)"
                        v-if="questionForm.option_arr && questionForm.option_arr.length > 1">
                        &#xe620;
                      </el-button>
                    </div>
                  </el-form-item>
                  <!-- 小题数据 -->
                  <div class="subtopic-data">
                    <!-- 题目类型 -->
                    <el-form-item label="选择类型" :prop="`option_arr.${que_index}.syque_typ_id`"
                      :rules="{ required: true, message: '请选择题目类型', trigger: 'change' }">
                      <el-select v-model="que.syque_typ_id" placeholder="请选择"
                        @change="chooseQuesType($event, que_index)">
                        <template v-for="item in questionType">
                          <el-option :key="item.syque_typ_id" :label="item.syque_typ_name" :value="item.syque_typ_id"
                            v-if="item.syque_typ_id != 14" />
                        </template>
                      </el-select>
                    </el-form-item>
                    <!-- 选择题选项 -->
                    <el-form-item label="选项" v-show="isChoice(que.syque_typ_id) && que.syque_typ_id">
                      <div class="question-options">
                        <!-- 多选选项 -->
                        <el-checkbox-group v-model="que.answer" v-if="que.syque_typ_id == 2">
                          <ul class="option-list">
                            <li v-for="(opt, opt_index) in que.question_item" :key="opt_index">
                              <div class="option">
                                <span class="option-no">
                                  {{ opt_index | changeEnChart }}
                                </span>
                                <el-form-item
                                  :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`">
                                  <try-editor v-model="opt.tetea_pra_ite_title"></try-editor>
                                </el-form-item>
                                <!-- 删除选项 -->
                                <el-button class="iconfont mini" type="custom_primary" circle
                                  @click="removeQueOption(que_index, opt_index)"
                                  v-if="que.question_item && que.question_item.length > 2">
                                  &#xe620;
                                </el-button>
                              </div>
                              <el-checkbox :label="opt_index">设为正确答案</el-checkbox>
                            </li>
                          </ul>
                        </el-checkbox-group>
                        <!-- 单选选项 -->
                        <el-radio-group v-model="que.answer" v-else>
                          <ul class="option-list">
                            <li v-for="(opt, opt_index) in que.question_item" :key="opt_index">
                              <div class="option">
                                <span class="option-no">
                                  {{ opt_index | changeEnChart }}
                                </span>
                                <el-form-item
                                  :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`">
                                  <try-editor v-model="opt.tetea_pra_ite_title"></try-editor>
                                </el-form-item>
                                <!-- 删除选项 -->
                                <el-button class="iconfont mini" type="custom_primary" circle
                                  @click="removeQueOption(que_index, opt_index)"
                                  v-if="que.question_item && que.question_item.length > 2">
                                  &#xe620;
                                </el-button>
                              </div>
                              <el-radio :label="opt_index">设为正确答案</el-radio>
                            </li>
                          </ul>
                        </el-radio-group>
                        <el-button type="custom_primary" size="medium" @click="appendQueOption(que_index)">
                          + 继续添加项
                        </el-button>
                      </div>
                    </el-form-item>
                    <!-- 非选择题答案 -->
                    <el-form-item v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id" label="答案"
                      v-for="(opt, opt_index) in que.question_item" :key="opt_index"
                      :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`">
                      <try-editor v-model="opt.tetea_pra_ite_title" placeholder="多项填空题答案使用英文 , 隔开"></try-editor>
                    </el-form-item>
                    <!-- 难易度 -->
                    <el-form-item label="难易度" :prop="`option_arr.${que_index}.tetea_pra_difficulty`"
                      :rules="{ required: true, message: '请选择题目难易程度', trigger: 'change' }">
                      <el-select v-model="que.tetea_pra_difficulty" placeholder="请选择">
                        <el-option label="简单" :value="10" />
                        <el-option label="普通" :value="20" />
                        <el-option label="困难" :value="30" />
                      </el-select>
                    </el-form-item>
                    <!-- 解析 -->
                    <el-form-item label="解析说明">
                      <try-editor v-model="que.tetea_pra_analysis"></try-editor>
                    </el-form-item>
                    <!-- 绑定知识点 -->
                    <el-form-item :label="prepareKnowledgeType==1?'绑定知识点':'绑定章节'">
                      <el-select v-if="prepareKnowledgeType==1" class="select" v-model="que.tetea_kno_id" placeholder="请选择">
                        <el-option v-for="prepare in prepareKnowledge" :key="prepare[valuekey]"
                          :label="prepare[labelkey]" :value="prepare[valuekey]" />
                      </el-select>
                      <el-cascader v-else v-model="que.tetea_kno_id" class="select" :options="prepareKnowledge" :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
                    </el-form-item>
                  </div>
                </div>
                <!-- 非组合题 -->
                <div v-if="questionForm.tetea_pra_ismakeup == 20">
                  <!-- 题目类型 -->
                  <el-form-item label="选择类型" :prop="`option_arr.${que_index}.syque_typ_id`"
                    :rules="{ required: true, message: '请选择题目类型', trigger: 'change' }">
                    <el-select v-model="que.syque_typ_id" placeholder="请选择" @change="chooseQuesType($event, que_index)">
                      <template v-for="item in questionType">
                        <el-option :key="item.syque_typ_id" :label="item.syque_typ_name" :value="item.syque_typ_id"
                          v-if="item.syque_typ_id != 14" />
                      </template>
                    </el-select>
                  </el-form-item>
                  <!-- 选择题选项 -->
                  <el-form-item label="选项" v-show="isChoice(que.syque_typ_id) && que.syque_typ_id">
                    <div class="question-options">
                      <!-- 多选选项 -->
                      <el-checkbox-group v-model="que.answer" v-if="que.syque_typ_id == 2">
                        <ul class="option-list">
                          <li v-for="(opt, opt_index) in que.question_item" :key="opt_index">
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`">
                                <try-editor v-model="opt.tetea_pra_ite_title"></try-editor>
                              </el-form-item>
                              <!-- 删除选项 -->
                              <el-button class="iconfont mini" type="custom_primary" circle
                                @click="removeQueOption(que_index, opt_index)"
                                v-if="que.question_item && que.question_item.length > 2">
                                &#xe620;
                              </el-button>
                            </div>
                            <el-checkbox :label="opt_index">设为正确答案</el-checkbox>
                          </li>
                        </ul>
                      </el-checkbox-group>
                      <!-- 单选选项 -->
                      <el-radio-group v-model="que.answer" v-else>
                        <ul class="option-list">
                          <li v-for="(opt, opt_index) in que.question_item" :key="opt_index">
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`">
                                <try-editor v-model="opt.tetea_pra_ite_title"></try-editor>
                              </el-form-item>
                              <!-- 删除选项 -->
                              <el-button class="iconfont mini" type="custom_primary" circle
                                @click="removeQueOption(que_index, opt_index)"
                                v-if="que.question_item && que.question_item.length > 2">
                                &#xe620;
                              </el-button>
                            </div>
                            <el-radio :label="opt_index">设为正确答案</el-radio>
                          </li>
                        </ul>
                      </el-radio-group>
                      <el-button type="custom_primary" size="medium" @click="appendQueOption(que_index)">
                        + 继续添加项
                      </el-button>
                    </div>
                  </el-form-item>
                  <!-- 非选择题答案 -->
                  <el-form-item v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id" label="答案"
                    v-for="(opt, opt_index) in que.question_item" :key="opt_index"
                    :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`">

                    <try-editor v-model="opt.tetea_pra_ite_title" placeholder="多项填空题答案使用英文 , 隔开"></try-editor>
                  </el-form-item>
                  <!-- 难易度 -->
                  <el-form-item label="难易度" :prop="`option_arr.${que_index}.tetea_pra_difficulty`"
                    :rules="{ required: true, message: '请选择题目难易程度', trigger: 'change' }">
                    <el-select v-model="que.tetea_pra_difficulty" placeholder="请选择">
                      <el-option label="简单" :value="10" />
                      <el-option label="普通" :value="20" />
                      <el-option label="困难" :value="30" />
                    </el-select>
                  </el-form-item>
                  <!-- 解析 -->
                  <el-form-item label="解析说明">
                    <try-editor v-model="que.tetea_pra_analysis"></try-editor>
                  </el-form-item>
                  <!-- 绑定知识点 -->
                  <el-form-item :label="prepareKnowledgeType==1?'绑定知识点':'绑定章节'">
                    <el-select v-if="prepareKnowledgeType==1" class="select" v-model="que.tetea_kno_id" placeholder="请选择">
                      <el-option v-for="prepare in prepareKnowledge" :key="prepare[valuekey]"
                        :label="prepare[labelkey]" :value="prepare[valuekey]" />
                    </el-select>
                    <el-cascader v-else v-model="que.tetea_kno_id" class="select" :options="prepareKnowledge" :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <div slot="footer">
          <el-button type="custom_info" size="small" @click="hideDialog('question')">取 消</el-button>
          <el-button type="custom_primary" size="small" @click="addQuestion" :disabled="btnload" v-loading="btnload">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <div v-for="(item, index) in 150" :key="index" :id="'mathContainer' + index" style="display: none;"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import html2canvas from 'html2canvas';
import { changeEnChart } from "@utils";
import { $uploadImg } from "@api/common";
import { $recognizeeduformula, $recognizeedupaperstructed, $recognizeeduquestionocr, $recognizeedupaperocr, $recognizeedupapercut } from "@api/homeworkExam";
var mathindex;
export default {
  name: "CuttingProblem",
  components: {},
  computed: {
    ...mapState("common", ["questionType"]),
    //获取背景偏移量
    getdragFrameBackLeft() {
      return this.cuttingLeft * -1 + this.paperOffsetLeft
    },
    //获取背景偏移量
    getdragFrameBackTop() {
      return this.cuttingTop * -1 + this.paperOffsetTop
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    // 判断当前是否选中的是否是单个空格
    isblank() {
      if (this.checkList.length > 0) {
        return this.checkList[this.optIndex].toString().includes('title') || this.checkList[this.optIndex].toString().includes('value')
      }
    },
  },
  props: {
    //展开上传
    value: {
      type: Boolean,
      default: false,
    },
    //加载中
    loadingall: {
      type: Boolean,
      default: false
    },
    //知识点/绑定列表
    emphasis:{
      type:Array,
      default:[]
    },
    // 类型 1知识点 2绑定章节
    emphasisType:{
      type:Number|String,
      default:1
    },
    labelkey:{
      type:String,
      default:"tetea_kno_title"
    },
    valuekey:{
      type:String,
      default:"tetea_kno_id"
    }
  },
  data() {
    /* 题目标题验证 */
    const tequeTitleValidate = (rule, value, callback) => {
      if (
        !this.questionForm.tetea_pra_title &&
        !this.questionForm.tetea_pra_title_image
      ) {
        // 未上传文件
        callback("请填写题干或者上传题干图片");
      }
      callback();
    };
    return {
      pageloadingall: false,
      //加载中
      btnload: false,
      //显示弹窗
      showCutting: this.value,

      // 是否启动勾选功能
      openCheck: false,
      //导入的题库数据jsonquan.data.questions
      questionDoc: [],
      // 试题切换展示模式
      checkTopic: true,
      //智能定位是否开启 0关 1开
      location: 1,
      //需要切割的题目下标
      checkList: [],
      // 当前选中的下标
      optIndex: 0,
      // 展开新增试题弹窗
      questionDialog: false,
      questionForm: {
        tetea_pra_ismakeup: 20,
        tetea_pra_title: '',
        option_arr: [{}],
      },
      questionRules: {
        tetea_pra_title: [{ validator: tequeTitleValidate, trigger: "change" },],
      },
      //正在添加的试题下标
      hierarchy: 0,
      /* 知识点/绑定列表 */
      prepareKnowledge: this.emphasis,
      // 类型 1知识点 2绑定章节
      prepareKnowledgeType:this.emphasisType,
      //试卷图片
      testPaperImgBase64: "",
      //试卷宽度
      imageWidth: 0,
      //拖拽选框时候的尺寸
      cuttingTop: 0,//距离头部
      cuttingLeft: 0,//距离左边
      cuttingInitialTop: 0,//距离头部
      cuttingInitialLeft: 0,//距离左边
      cuttingHeight: 0,//页面高度
      cuttingWidth: 0,//页面宽度
      //选框里面的操作栏
      operatingStyle: {
        left: 0,
        top: 0
      },

      //拖拽试卷时候的偏移
      paperInitialX: 0,//点击初定位始值
      paperInitialY: 0,
      paperOffsetLeft: 0,//移动停止的值 记录点击之前的位置
      paperOffsetTop: 0,
      // 是否开启试卷拖拽
      paperDrag: false,
      // 手指是否开启滑动
      open_move_use: false,
      //滑动完成停止操作
      mousemoveStop: false,
      // 生成中
      generateLoading: false,
    };
  },
  watch: {
    value(n, o) {
      this.showCutting = n;
      if (n) {
        // 清空页面数据
        this.openCheck = false;
        this.questionDoc = [];
        this.checkTopic = true;
        this.location = 1;
        this.checkList = [];
        this.optIndex = 0;
        this.questionDialog = false;
        this.questionForm = {
          tetea_pra_ismakeup: 20,
          tetea_pra_title: '',
          option_arr: [{}],
        };
        this.hierarchy = 0;
        this.testPaperImgBase64 = "";
      }
    },
    //知识点切换
    emphasis(n, o) {
      this.prepareKnowledge = n
    },
    //知识点切换
    emphasisType(n, o) {
      this.prepareKnowledgeType = n
    },
    //按钮加载中
    loadingall(n, o) {
      this.pageloadingall = n
    }
  },
  async created() {
    // 记录次数
    mathindex = 0
  },
  async mounted() {
    // 关闭勾选框
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    // 上传的base
    uploadPaper(file) {
      // 清除拖拽框
      this.closeDragFrame()
      if (file.size >= 10485760) {
        this.$message({
          showClose: true,
          message: '图片最大上传10M',
          type: 'warning'
        });
        this.$refs.myUpload.clearFiles()
        return false;
      }

      this.generateLoading = true
      this.getBase64(file.raw).then(async (res) => {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        //这里请求获取识别的内容
        let imgFile = this.dataURLtoFile(res);
        //精细版结构化识别
        let formData = new FormData();
        formData.append("body", imgFile, imgFile.name);
        formData.append("first", 1);
        let { data } = await $recognizeedupaperstructed(formData);
        data.map(async item => {
          if (item.tetea_pra_title.length) {
            item.tetea_pra_title = await this.getMath(item.tetea_pra_title)
            // this.$forceUpdate()
          }
          // 组合题
          if (item.syque_typ_id == 14) {
            if (item.option_arr.length) {
              item.option_arr.map(async item2 => {
                if (item2.tetea_pra_title) {
                  item2.tetea_pra_title = await this.getMath(item2.tetea_pra_title)
                  // this.$forceUpdate()
                }
                if (item2.question_item.length) {
                  item2.question_item.map(async (item3,index3) => {
                    item3.teque_ite_code=letter[index3]
                    if (item3.tetea_pra_ite_title.length) {
                      if (item3.tetea_pra_ite_title) {
                        item3.tetea_pra_ite_title = await this.getMath(item3.tetea_pra_ite_title)
                      }
                    }
                  })
                }else{
                  item2.question_item=[{
                    teque_ite_code: letter[0],
                    tetea_pra_ite_title: "",
                    tetea_pra_ite_isanswer: 10
                  }]
                }
                if (item2.tetea_pra_analysis.length) {
                  item2.tetea_pra_analysis = await this.getMath(item2.tetea_pra_analysis)
                }
              })
            }
          } else {
            // 常规题
            if (item.tetea_pra_title) {
              item.tetea_pra_title = await this.getMath(item.tetea_pra_title)
            }
            if (item.question_item.length) {
              item.question_item.map(async (item3,index3) => {
                item3.teque_ite_code=letter[index3]
                if (item3.tetea_pra_ite_title.length) {
                  if (item3.tetea_pra_ite_title) {
                    item3.tetea_pra_ite_title = await this.getMath(item3.tetea_pra_ite_title)
                  }
                }
              })
            }else{
              item.question_item=[{
                teque_ite_code: letter[0],
                tetea_pra_ite_title: "",
                tetea_pra_ite_isanswer: 10
              }]
            }
            if (item.tetea_pra_analysis.length) {
              item.tetea_pra_analysis = await this.getMath(item.tetea_pra_analysis)
            }
          }
        })
        this.questionDoc = [...this.questionDoc, ...data]

        this.testPaperImgBase64 = res
        //获取上传的图片宽度
        const img = new Image();
        img.src = res;
        img.onload = () => {
          this.imageWidth = img.width;
        };
        this.cuttingTop = 0;
        this.cuttingLeft = 0;
        this.paperOffsetLeft = 0;
        this.paperOffsetTop = 0;
        this.generateLoading = false
        this.$refs.myUpload.clearFiles()
      }).catch((e) => {
        this.$refs.myUpload.clearFiles()
        this.generateLoading = false
      });
    },
    //通过传入的数据转移成为Math格式
    async getMath(str) {
      let newStr = str;
      const regex = /<latex>\s*(.*?)\s*<\/latex>/g;
      let match;
      while ((match = regex.exec(str)) !== null) {
        let math = "";
        let container = document.getElementById('mathContainer' + mathindex);
        mathindex++
        container.innerHTML = '\\(' + match[1] + '\\)';
        await new Promise((resolve) => {
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, container], () => {
            resolve();
          });
        });
        // 获取渲染后的内容
        const doc = new DOMParser().parseFromString(container.innerHTML, 'text/html');
        math = doc.querySelector('math') ? doc.querySelector('math').outerHTML : '';
        newStr = newStr.replace(match[0], math);
      }
      return newStr
    },
    //开启和关闭拖拽试卷
    movePaperClick() {
      this.paperDrag = !this.paperDrag
      // 清除拖拽框
      this.closeDragFrame()
    },
    //滚动中键
    handleScroll(event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        //图片是否大于容器
        if (this.imageWidth - 50 > this.$refs.drawingBoard.offsetWidth) {
          this.imageWidth = Math.ceil(this.imageWidth - 50); // 缩小图片
        } else {
          this.imageWidth = this.$refs.drawingBoard.offsetWidth; // 缩小图片
        }
      } else {
        this.imageWidth = Math.ceil(this.imageWidth + 50); // 放大图片
      }
    },
    // 鼠标按钮被按下时发生
    mousedownPaper(e) {
      //是否禁止滑动
      if (this.mousemoveStop) {
        return false;
      }
      this.open_move_use = true
      // 鼠标点与电脑窗口距离
      let pageX = 0
      let pageY = 0
      // 由于手机端和pc端获取页面坐标方式不同，所以需要做出判断
      if (e.changedTouches && e.changedTouches.length) {
        pageX = e.changedTouches[0].pageX
        pageY = e.changedTouches[0].pageY
      } else {
        pageX = e.pageX
        pageY = e.pageY
      }
      // 判断是否推拽的试卷
      if (this.paperDrag) {
        this.paperInitialX = pageX - e.target.parentNode.offsetLeft - this.paperOffsetLeft;
        this.paperInitialY = pageY - e.target.parentNode.offsetTop - this.paperOffsetTop;
      } else {
        // 没有勾选试题或者正在勾选中
        if (this.checkList.length == 0 || this.openCheck) {
          setTimeout(() => {
            if (!this.paperDrag) {
              this.$message({
                showClose: true,
                message: '请先完成试题勾选后再操作'
              });
            }
          }, 300);
          return false;
        }
        this.cuttingLeft = pageX - e.target.parentNode.offsetLeft;
        this.cuttingTop = pageY - e.target.parentNode.offsetTop;
        this.cuttingInitialLeft = pageX - e.target.parentNode.offsetLeft;
        this.cuttingInitialTop = pageY - e.target.parentNode.offsetTop;
        this.cuttingHeight = 0;
        this.cuttingWidth = 0;
      }

    },
    // 鼠标在某个元素上滚动的时候发生
    mousemovePaper(e) {
      //是否允许滑动产生数据
      if (this.open_move_use) {
        // 容器距离最外层尺寸
        let offsetLeft = e.target.parentNode.offsetLeft
        let offsetTop = e.target.parentNode.offsetTop
        // 鼠标点与电脑窗口距离
        let pageX = 0
        let pageY = 0
        // 由于手机端和pc端获取页面坐标方式不同，所以需要做出判断
        if (e.changedTouches && e.changedTouches.length) {
          pageX = e.changedTouches[0].pageX
          pageY = e.changedTouches[0].pageY
        } else {
          pageX = e.pageX
          pageY = e.pageY
        }
        if (this.paperDrag) {
          // 拖拽试卷
          this.paperOffsetLeft = pageX - offsetLeft - this.paperInitialX;
          this.paperOffsetTop = pageY - offsetTop - this.paperInitialY;
        } else {
          // 没有勾选试题或者正在勾选中
          if (this.checkList.length == 0 || this.openCheck) {
            return false;
          }
          // 使用拖拽框
          // 设置框高度和宽度
          this.cuttingWidth = Math.abs(pageX - offsetLeft - this.cuttingInitialLeft);
          this.cuttingHeight = Math.abs(pageY - offsetTop - this.cuttingInitialTop);
          //判断是是否往左拉动
          if (pageX - offsetLeft - this.cuttingInitialLeft < 0) {
            this.cuttingLeft = this.cuttingInitialLeft - Math.abs(pageX - offsetLeft - this.cuttingInitialLeft)
          }
          //判断是否往上拉动
          if (pageY - offsetTop - this.cuttingInitialTop < 0) {
            this.cuttingTop = this.cuttingInitialTop - Math.abs(pageY - offsetTop - this.cuttingInitialTop)
          }
        }
      }

    },
    // 鼠标按键被松开时发生
    mouseupPaper(e) {
      // 设置操作台定位
      // 判断是否为滑动结束，
      if (this.open_move_use) {
        // 通过判断容器是否存在，存在就禁止点击容器产生事件
        if (this.cuttingWidth > 0 || this.cuttingHeight > 0) {
          //禁止滑动事件 为了阻止切题后点击下面按钮重新启动切割事件
          this.mousemoveStop = true
          // 容器距离最外层尺寸
          let offsetLeft = e.target.parentNode.offsetLeft
          let offsetTop = e.target.parentNode.offsetTop
          // 结束距离左上角距离
          let maringX = 0;//距离左边
          let maringY = 0;//距离头部
          // 由于手机端和pc端获取页面坐标方式不同，所以需要做出判断
          if (e.changedTouches && e.changedTouches.length) {
            maringX = e.changedTouches[0].pageX - offsetLeft
            maringY = e.changedTouches[0].pageY - offsetTop
          } else {
            maringX = e.pageX - offsetLeft
            maringY = e.pageY - offsetTop
          }
          let operatingStyle = {}
          //试卷窗口 宽度和高度
          let offsetWidth = e.target.parentNode.offsetWidth
          let offsetHeight = e.target.parentNode.offsetHeight

          //获取右下角点距离头部 
          let rightTop = 0
          // 初始点大于结束点说明反向的
          if (this.cuttingInitialTop > maringY) {
            rightTop = this.cuttingInitialTop
          } else {
            rightTop = maringY
          }
          //获取右下角点距离左边
          let topLeft = 0
          // 初始点大于结束点说明反向的
          if (this.cuttingInitialLeft > maringX) {
            topLeft = this.cuttingInitialLeft
          } else {
            topLeft = maringX
          }
          //  拖拽框 高度度接近模型搞度的时候  并且试卷宽度-拖拽框右下角高度<70
          if (offsetHeight - this.cuttingHeight < 130 && offsetHeight - rightTop < 90) {
            if (this.cuttingWidth > 340) {
              // 内容放置到里面右下角
              operatingStyle = { right: "10px", bottom: "10px" }
            } else {
              // 当前定位大于半个屏幕的时候
              if (topLeft > offsetWidth / 2) {
                operatingStyle = { right: "10px", bottom: "10px" }
              } else {
                operatingStyle = { left: "10px", bottom: "10px" }
              }
            }
          } else {
            // 剩余高度可以存放控制台
            // 窗口高度-窗口右下角高度>70）
            if (offsetHeight - rightTop > 90) {
              operatingStyle.top = "100%"
            } else {
              operatingStyle.bottom = "100%"
              operatingStyle.marginBottom = "10px"
            }
            // 当前定位大于半个屏幕的时候
            if (topLeft > offsetWidth / 2) {
              operatingStyle.right = "0px"
            } else {
              operatingStyle.left = "0px"
            }
          }
          this.operatingStyle = operatingStyle
        }
        this.open_move_use = false;
      }
    },
    //关闭勾选框
    handleKeyDown(event) {
      //按了Esc按键
      if (event.key == "Escape" && this.mousemoveStop) {
        // 关闭动画
        this.cuttingWidth = 0
        this.cuttingHeight = 0
        // 允许再次滑动
        this.mousemoveStop = false
      }
    },
    //图片转base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    //关闭试题切割
    hideCutting() {
      this.$emit("hideimgup");
    },
    //切换上下一题
    setQuestionsIndex(index) {
      this.optIndex = index
      this.goScroll('chenc-' + this.checkList[index])
    },
    //滚动到指定位置
    goScroll(id) {
      // 是否开启滚动
      if (this.location == 1) {
        const parent = document.getElementById("lists");
        const target = document.getElementById(id);
        if (target) {
          parent.scrollTo({
            top: target.offsetTop - parent.offsetTop - 10,
            behavior: 'smooth'
          });
        }
      }
    },
    //勾选
    checkAdjust() {
      this.openCheck = true;
      // 清除拖拽框
      this.closeDragFrame()
    },
    //取消勾选
    cancelAdjust() {
      this.checkList = [];
      this.openCheck = false;
      // 关闭拖拽框
      this.closeDragFrame()
    },
    //确认勾选
    submitCheck() {
      // 关闭拖拽框
      this.closeDragFrame()
      this.optIndex = 0
      setTimeout(() => {
        this.goScroll('chenc-' + this.checkList[0])
      }, 300);
      this.openCheck = false;
    },
    //提交试题
    async submitProblem() {
      // 关闭拖拽框
      this.closeDragFrame()
      let pass = true
      let error = ""
      let newsDoc = JSON.parse(JSON.stringify(this.questionDoc))
      newsDoc.map((item, index) => {
        if(this.prepareKnowledgeType==2){
          if(item.tetea_kno_id&&item.tetea_kno_id.length){
              item.tetea_kno_id=item.tetea_kno_id[item.tetea_kno_id.length - 1]
          }
        }
        if (pass && (!item.hasOwnProperty('tetea_pra_title') || item.tetea_pra_title.length < 1)) {
          error = "请填写第" + (index + 1) + "题题目"
          pass = false
        }
        // 组合题
        if (item.syque_typ_id == 14) {
          item.option_arr.map((item2, index2) => {
            if (pass && (!item2.hasOwnProperty('tetea_pra_title') || item2.tetea_pra_title.length < 1)) {
              error = "请填写第" + (index + 1) + "题第" + (index2 + 1) + "小题题目"
              pass = false
            }
                
            if(this.prepareKnowledgeType==2){
              if(item2.tetea_kno_id&&item2.tetea_kno_id.length){
                item2.tetea_kno_id=item2.tetea_kno_id[item2.tetea_kno_id.length - 1]
              }
            }
            if (item2.syque_typ_id == 1) {
              //遍历选择题里面的小题
              item2.question_item.map(item3 => {
                if (item2.answer == item3.teque_ite_code) {
                  item3.tetea_pra_ite_isanswer = 10
                } else {
                  item3.tetea_pra_ite_isanswer = 20
                }
              })
              let ispass = item2.answer&&item2.answer.length > 0
              let isvalpass = item2.question_item.some(function (item3) {
                return item3.tetea_pra_ite_title == ""
              })
              if (pass && !ispass) {
                error = "请勾选第" + (index + 1) + "题第" + (index2 + 1) + "小题正确答案"
                pass = false
              }
              if (pass && isvalpass) {
                error = "请填写第" + (index + 1) + "题第" + (index2 + 1) + "小题正确答案或选项值"
                pass = false
              }
            }
            if (item2.syque_typ_id == 5 || item2.syque_typ_id == 2) {
              //遍历选择题里面的小题
              if(item2.syque_typ_id == 5){
                item2.question_item.map(item3 => {
                  if (item2.answer == item3.teque_ite_code) {
                    item3.tetea_pra_ite_isanswer = 10
                  } else {
                    item3.tetea_pra_ite_isanswer = 20
                  }
                })
              }
              let ispass = item2.question_item.some(function (item3) {
                return item3.tetea_pra_ite_isanswer == 10
              })
              let isvalpass = item2.question_item.some(function (item3) {

                return item3.tetea_pra_ite_title == ""
              })
              if (pass && !ispass) {
                error = "请勾选第" + (index + 1) + "题第" + (index2 + 1) + "小题正确答案"
                pass = false
              }
              if (pass && isvalpass) {
                error = "请填写第" + (index + 1) + "题第" + (index2 + 1) + "小题正确答案或选项值"
                pass = false
              }
            }
            if (item2.syque_typ_id == 3 || item2.syque_typ_id == 4 || item2.syque_typ_id == 7 || item2.syque_typ_id == 8) {
              let tetea_pra_ite_title = []
              item2.question_item.map((item3, index3) => {
                //填空题设置默认值
                if (item3.tetea_pra_ite_title && item3.tetea_pra_ite_title.length > 0) {
                  tetea_pra_ite_title.push(item3.tetea_pra_ite_title)
                } else {
                  error = "请填写第" + (index + 1) + "题第" + (index2 + 1) + "小题正确答案"
                  pass = false
                }
              })
              if (item2.question_item.length == 0 && pass) {
                error = "请添加第" + (index + 1) + "题第" + (index2 + 1) + "小题正确答案"
                pass = false
              }
              item2.question_item = [{ teque_ite_code: "", tetea_pra_ite_isanswer: "10", tetea_pra_ite_title: tetea_pra_ite_title.toString() }]
            }
            if (item2.tetea_pra_difficulty == 0) {
              if (pass) {
                error = "请选择第" + (index + 1) + "题第" + (index2 + 1) + "小题困难度"
                pass = false
              }
            }
          })
        } else {
          if (item.syque_typ_id == 1) {
            //遍历选择题里面的小题
            item.question_item.map(item2 => {
              if (item.answer == item2.teque_ite_code) {
                item2.tetea_pra_ite_isanswer = 10
              } else {
                item2.tetea_pra_ite_isanswer = 20
              }
            })
            let ispass = item.answer&&item.answer.length > 0
            let isvalpass = item.question_item.some(function (item2) {
              return item2.tetea_pra_ite_title == ""
            })
            if (pass && !ispass) {
              error = "请勾选第" + (index + 1) + "题正确答案"
              pass = false
            }
            if (pass && isvalpass) {
              error = "请填写第" + (index + 1) + "题正确答案或选项值"
              pass = false
            }
          }
          if (item.syque_typ_id == 5 || item.syque_typ_id == 2) {
            //遍历选择题里面的小题
            if(item.syque_typ_id == 5){
              item.question_item.map(item2 => {
                if (item.answer == item2.teque_ite_code) {
                  item2.tetea_pra_ite_isanswer = 10
                } else {
                  item2.tetea_pra_ite_isanswer = 20
                }
              })
            }
            let ispass = item.question_item.some(function (item2) {
              return item2.tetea_pra_ite_isanswer == 10
            })
            let isvalpass = item.question_item.some(function (item2) {

              return item2.tetea_pra_ite_title == ""
            })
            if (pass && !ispass) {
              error = "请勾选第" + (index + 1) + "题正确答案"
              pass = false
            }
            if (pass && isvalpass) {
              error = "请填写第" + (index + 1) + "题正确答案或选项值"
              pass = false
            }
          }
          if (item.syque_typ_id == 3 || item.syque_typ_id == 4 || item.syque_typ_id == 7 || item.syque_typ_id == 8) {
            let tetea_pra_ite_title = []
            item.question_item.map((item2, index2) => {
              //填空题设置默认值
              if (item2.tetea_pra_ite_title.length > 0) {
                tetea_pra_ite_title.push(item2.tetea_pra_ite_title)
              } else {
                error = "请填写第" + (index + 1) + "题正确答案"
                pass = false
              }
            })
            if (item.question_item.length == 0 && pass) {
              error = "请添加第" + (index + 1) + "题正确答案"
              pass = false
            }
            item.question_item = [{ teque_ite_code: "", tetea_pra_ite_isanswer: "10", tetea_pra_ite_title: tetea_pra_ite_title.toString() }]
          }
          if (item.tetea_pra_difficulty == 0) {
            if (pass) {
              error = "请选择第" + (index + 1) + "题困难度"
              pass = false
            }
          }
          let newsitem=JSON.parse(JSON.stringify(item))
          item.option_arr=[newsitem]
        }
      })
      if (!pass) {
        this.$message({
          type: "info",
          duration: 2500,
          message: error
        });
      } else {
        this.$emit("submit", newsDoc)
      }
    },
    //判断是否选中
    isCheck(str) {
      return this.checkList.includes(str.toString())
    },
    // 操作台 type  1题目识别 2试卷切题识别 3精细版结构化识别 4公式识别 5整页试卷识别 6使用图片 7取消   operationType 1追加 2替换
    capture(type,operationType) {
      if (type == 7) {
        // 关闭动画
        this.cuttingWidth = 0
        this.cuttingHeight = 0
        // 允许再次滑动
        this.mousemoveStop = false
        return false
      }
      const element = document.getElementById('captureArea'); // 需要截图的区域的id
      this.generateLoading = true
      html2canvas(element).then(async (canvas) => {
        //base64 图片数据 canvas.toDataURL('image/png');
        //转换成数据流
        let imgFile = this.dataURLtoFile(canvas.toDataURL('image/png'));
        let formData = new FormData();
        formData.append("body", imgFile, imgFile.name);
        switch (type) {
          case 1:
            //题目识别
            if (this.checkList[this.optIndex].toString().includes('title') || this.checkList[this.optIndex].toString().includes('value')) {
              let res1 = await $recognizeeduquestionocr(formData);
              let content = await this.getMath(res1.data.content)
              //递归迭代设置数据
              this.setQuestionDocItem(content, this.checkList[this.optIndex].toString().split('-'), this.questionDoc,operationType)
            } else {
              this.$message({
                showClose: true,
                type: "warning",
                duration: 1500,
                message: "请勾选标题或答案",
              });
            }

            // 关闭拖拽框
            this.closeDragFrame()
            // 关闭等待弹窗
            this.generateLoading = false
            break;
          case 2:
            //试卷切题识别
            // formData.append("subject", "数学");
            if (this.checkList[this.optIndex].toString().includes('title') || this.checkList[this.optIndex].toString().includes('value')) {
              let res2 = await $recognizeedupapercut(formData);
              if (res2.data.length > 0) {
                let titles = res2.data.map(obj => obj.title).join('');
                let content2 = await this.getMath(titles)
                if (content2.length > 0) {
                  //递归迭代设置数据
                  this.setQuestionDocItem(content2, this.checkList[this.optIndex].toString().split('-'), this.questionDoc,operationType)
                } else {
                  this.$message({
                    showClose: true,
                    message: '识别失败,请重新勾选'
                  });
                }
              } else {
                this.$message({
                  showClose: true,
                  message: '识别失败,请重新勾选,请重试'
                });
              }
            } else {
              this.$message({
                showClose: true,
                type: "warning",
                duration: 1500,
                message: "请勾选标题或答案",
              });
            }
            // 关闭拖拽框
            this.closeDragFrame()
            // 关闭等待弹窗
            this.generateLoading = false
            break;
          case 3:
            //精细版结构化识别
            if (!this.checkList[this.optIndex].toString().includes('title') && !this.checkList[this.optIndex].toString().includes('value')) {
              formData.append("first", 1);
              let res3 = await $recognizeedupaperstructed(formData);
              console.log(res3.data)
              // 超过一道题
              if (res3.data.length > 0) {
                let question = res3.data[0]
                // 判断是否是组合题
                if (question.syque_typ_id == 14) {
                  // 判断是否选中的是子集。自己不能是组合题
                  if (this.checkList[this.optIndex].includes("-")) {
                    this.$message({
                      showClose: true,
                      type: "warning",
                      duration: 1500,
                      message: "二级不能是组合题",
                    });
                  } else {
                    // 设置组合题
                    this.setCombination(question)
                  }
                } else {
                  // 非组合题 
                  this.setQuestionItem(question, this.checkList[this.optIndex].toString().split('-'))
                }
              }
            } else {
              this.$message({
                showClose: true,
                type: "warning",
                duration: 1500,
                message: "请勾选标题或答案",
              });
            }
            // 关闭拖拽框
            this.closeDragFrame()
            // 关闭等待弹窗
            this.generateLoading = false
            break;
          case 4:
            // 对公式进行识别
            if (this.checkList[this.optIndex].toString().includes('title') || this.checkList[this.optIndex].toString().includes('value')) {
              let res4 = await $recognizeeduformula(formData);
              if (res4.data.content) {
                console.log(res4.data.content)
                let content4 = await this.getMath(res4.data.content)
                console.log(content4)
                if (content4.length > 0) {
                  //递归迭代设置数据
                  this.setQuestionDocItem(content4, this.checkList[this.optIndex].toString().split('-'), this.questionDoc,operationType)
                } else {
                  this.$message({
                    showClose: true,
                    message: '识别失败,请重新勾选'
                  });
                }
              } else {
                this.$message({
                  showClose: true,
                  message: '识别失败,请重新勾选'
                });
              }
            } else {
              this.$message({
                showClose: true,
                type: "warning",
                duration: 1500,
                message: "请勾选标题或答案",
              });
            }
            // 关闭拖拽框
            this.closeDragFrame()
            // 关闭等待弹窗
            this.generateLoading = false
            //公式识别
            break;
          case 5:
            //整页试卷识别
            let res5 = await $recognizeedupaperocr(formData);
            if (res5.data.content) {
              let content5 = await this.getMath(res5.data.content)
              if (content5.length > 0) {
                //递归迭代设置数据
                this.setQuestionDocItem(content5, this.checkList[this.optIndex].toString().split('-'), this.questionDoc,operationType)
              } else {
                this.$message({
                  showClose: true,
                  message: '识别失败,请重新勾选'
                });
              }
            } else {
              this.$message({
                showClose: true,
                message: '识别失败,请重新勾选'
              });
            }
            // 关闭拖拽框
            this.closeDragFrame()
            // 关闭等待弹窗
            this.generateLoading = false
            break;
          case 6:
            // 是否对单个题目或答案进行编写状态 4,6需要调用
            if (this.checkList[this.optIndex].toString().includes('title') || this.checkList[this.optIndex].toString().includes('value')) {
              // 生成图直接使用
              let res6 = await $uploadImg(imgFile);
              //递归迭代设置数据
              this.setQuestionDocItem(`<img src="${res6.data.address}" width="${this.cuttingWidth}" height="${this.cuttingHeight}" alt="" />`, this.checkList[this.optIndex].toString().split('-'), this.questionDoc,operationType)
            } else {
              this.$message({
                showClose: true,
                type: "warning",
                duration: 1500,
                message: "请勾选标题或答案",
              });
            }
            // 关闭拖拽框
            this.closeDragFrame()
            // 关闭等待弹窗
            this.generateLoading = false
            break;
        }
        // setTimeout(() => {
        //   this.closeDragFrame()
        //   // 关闭等待弹窗
        //   this.generateLoading=false
        // }, 1000);

      }).catch(() => {
        this.generateLoading = false
        this.mousemoveStop = false
      });
    },
    //
    //将得到的值筛入数据 values需要替换的值  indexs需要修改的数据组  question修改的数据
    setQuestionDocItem(values, indexs, question,operationType) {
      if (indexs.length > 1) {
        let itemindex = parseInt(indexs[0])
        console.log("判断是否是数组",Array.isArray(question))
        // 判断是否是数组
        if (Array.isArray(question)) {
          // 判断是否是组合题， 并且不是修改组合题的题目
          if (question[itemindex].syque_typ_id == 14 && indexs[1] != 'title') {
            // 组合题 
            this.setQuestionDocItem(values, indexs.slice(1), question[itemindex].option_arr,operationType)
          } else {
            // 普通题目
            this.setQuestionDocItem(values, indexs.slice(1), question[itemindex],operationType)
          }
        } else {
          // 对象 判断是否修改的标题
          if(indexs[1] != 'title'){
            // 修改的答案
            this.setQuestionDocItem(values, indexs.slice(1), question.question_item[itemindex],operationType)
          }else{
            this.setQuestionDocItem(values, indexs.slice(1), question,operationType)
          }
        }
      } else {
        if (indexs == 'title') {
          //修改的是名称
          question.tetea_pra_title = operationType==1?((question.tetea_pra_title?question.tetea_pra_title:"")+values):values
        } else if (indexs == 'value') {
          //修改的答案
          question.tetea_pra_ite_title = operationType==1?((question.tetea_pra_ite_title?question.tetea_pra_ite_title:"")+values):values
        } else {
          question[indexs[0]] = operationType==1?((question[indexs[0]]?question[indexs[0]]:"")+values):values
        }
        //跳转到下一个
        if (this.optIndex < this.checkList.length - 1) {
          this.optIndex++
          // 定位到正在处理的位置
          this.goScroll('chenc-' + this.checkList[this.optIndex])
        }
        this.$forceUpdate()
      }
    },
    //设置单题内容
    async setQuestionItem(item, indexs) {
      // 常规题
      if (item.tetea_pra_title) {
        item.tetea_pra_title = await this.getMath(item.tetea_pra_title);
      }

      if (item.question_item.length) {
        await Promise.all(item.question_item.map(async item3 => {
          if (item3.tetea_pra_ite_title.length && item3.tetea_pra_ite_title) {
            item3.tetea_pra_ite_title = await this.getMath(item3.tetea_pra_ite_title);
          }
        }));
      }
      if (item.tetea_pra_analysis.length) {
        item.tetea_pra_analysis = await this.getMath(item.tetea_pra_analysis);
      }
      if (indexs.length > 1) {
        this.questionDoc[indexs[0]].option_arr[indexs[1]] = item
      } else {
        this.questionDoc[indexs[0]] = item
      }
      this.$forceUpdate()

    },
    //设置组合题内容
    async setCombination(questions) {
      if (questions.option_arr.length) {
        await Promise.all(questions.option_arr.map(async item2 => {
          if (item2.tetea_pra_title) {
            item2.tetea_pra_title = await this.getMath(item2.tetea_pra_title);
          }
          if (item2.question_item.length) {
            await Promise.all(item2.question_item.map(async item3 => {
              if (item3.tetea_pra_ite_title.length) {
                if (item3.tetea_pra_ite_title) {
                  item3.tetea_pra_ite_title = await this.getMath(item3.tetea_pra_ite_title);
                }
              }
            }));
          }
          if (item2.tetea_pra_analysis.length) {
            item2.tetea_pra_analysis = await this.getMath(item2.tetea_pra_analysis);
          }
        }));
      }
      this.$set(this.questionDoc, this.checkList[this.optIndex], questions)
    },

    /**
     * 转换base64编码图片转换为file类型图片文件
     * @param {string} dataurl base64编码图片
     * @param {string} filename 文件类型
     */
    dataURLtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    // 关闭拖拽框
    closeDragFrame() {
      // 关闭动画
      this.cuttingWidth = 0
      this.cuttingHeight = 0
      // 释放允许选框操作
      this.mousemoveStop = false
      //模拟请求后干掉图片
    },
    //删除大题
    deleteQuestionDocItem(indexArray) {
      let indexs = indexArray.toString().split('-')
      if (indexs.length == 1) {
        this.questionDoc.splice(parseInt(indexs[0]), 1)
      } else {
        this.questionDoc[parseInt(indexs[0])].option_arr.splice(parseInt(indexs[1]), 1)
      }
      // if( this.questionDoc.length>1){
      //     this.questionDoc.splice(index,1)
      // }else{
      //     this.$message({
      //         type: "info",
      //         duration: 1500,
      //         message: "至少保留一个大题",
      //     });
      // }
    },
    //添加小题答案
    addDocQuestion(item, index) {
      if (item.question_item.length < 20) {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        item.question_item.push({ teque_ite_code: letter[item.question_item.length], tetea_pra_ite_isanswer: 20, tetea_pra_ite_title: "" })
        // this.questionDoc[index]=item
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "答案上限",
        });
      }
    },
    //删除答案
    deleteDocQuestionItem(item, index2) {
      if (item.question_item.length > 1) {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        if (item.syque_typ_id == 1 || item.syque_typ_id == 5) {
          if (item.answer == item.question_item[index2].teque_ite_code) {
            item.answer = ""
          }
        }
        item.question_item.splice(index2, 1)
        item.question_item.map((item2, index) => {
          item2.teque_ite_code = letter[index]
        })
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "至少保留一个答案",
        });
      }
    },
    // 点击勾选试题
    isCheckChange(vals) {
      let str = vals.toString()
      let checkList = this.checkList
      // 判断是否存在，存在就删除
      if (checkList.includes(str)) {
        checkList.splice(checkList.indexOf(str), 1)
      } else {
        let strarray = str.split('-')
        // 点击的题目下标
        if (strarray.length > 1) {
          // 点击的二级标题 出现过说明他的父级已经选中 不能再选下面
          if (checkList.includes(strarray[0]) || checkList.includes(strarray[0] + '-' + strarray[1])) {
            this.$message({ message: '整题已被选中', type: 'warning' });
            return false;
          }
        }
        // let result = str.substring(0, str.lastIndexOf('-'));
        //将子集被选中的数据删除
        let filteredArr = checkList.filter(item => !item.startsWith(str));
        checkList = filteredArr
        // 标题或者答案选中
        // 不存在就追加
        checkList.push(str)
      }
      checkList = this.sortTree(checkList)
      // 自定义排序函数
      this.checkList = checkList
    },
    // 将元素按树形结构排序
    sortTree(arr) {
      // 提取数字前缀并将其作为树的层级
      const parsePrefix = str => {
        // 提取前缀的数字部分
        const match = str.match(/^(\d+(?:-\d+)*)/);
        return match ? match[1].split('-').map(Number) : [];
      };

      // 排序函数
      const compare = (a, b) => {
        const prefixA = parsePrefix(a);
        const prefixB = parsePrefix(b);

        // 比较两个前缀的层级
        for (let i = 0; i < Math.min(prefixA.length, prefixB.length); i++) {
          if (prefixA[i] !== prefixB[i]) {
            return prefixA[i] - prefixB[i];
          }
        }
        return prefixA.length - prefixB.length; // 短前缀在长前缀之前
      };

      // 排序并处理非字符串类型
      const sortedArray = arr
        .map(item => typeof item === 'number' ? item.toString() : item) // 转换数字为字符串
        .sort(compare);

      return sortedArray;
    },
    /** 选中题目是否为组合题 */
    checkIsCompose(val) {
      let { tetea_pra_title, tetea_pra_title_image } = this.questionForm;
      let form = { tetea_pra_ismakeup: val, option_arr: [{}], tetea_pra_title }
      if (tetea_pra_title_image) form.tetea_pra_title_image = tetea_pra_title_image
      this.questionForm = { ...form };
      this.$refs.questionForm.clearValidate();
      this.$forceUpdate();
    },
    /**
     * 选中题目题型
     * @param {number} val 题型选中值
     * @param {number} que_index 题目数组下标
     */
    chooseQuesType(val, que_index) {
      let option = {
        tetea_pra_ite_title: "",
        tetea_pra_ite_isanswer: 20
      };
      let question_item = [];
      let { option_arr } = this.questionForm
      if (this.isChoice(val)) {
        let loopTime = (val == 5 && 2) || 4;
        for (let i = 0; i < loopTime; i++) {
          question_item.push({ ...option });
        }
      } else {
        question_item = [{
          tetea_pra_ite_title: "",
          tetea_pra_ite_isanswer: 10
        }]
      }
      option_arr[que_index] = {
        ...option_arr[que_index],
        answer: val == 2 && [] || '',
        question_item: [...question_item]
      }
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /** 添加组合题小题 */
    appendQues() {
      let { option_arr } = this.questionForm;
      option_arr.push({});
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 移除组合题小题
     * @param {number} que_index 题目数组下标
     */
    removeQues(que_index) {
      let { option_arr } = this.questionForm;
      this.$_.pullAt(option_arr, que_index);
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 添加题目选项
     * @param {number} que_index 题目数组下标
     */
    appendQueOption(que_index) {
      let { option_arr } = this.questionForm
      let { question_item } = option_arr[que_index];
      const option = {
        tetea_pra_ite_title: "",
        tetea_pra_ite_isanswer: 20
      };
      question_item.push(option);
      option_arr[que_index] = { ...option_arr[que_index], question_item: [...question_item] };
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
    },
    /**
     * 移除题目选项
     * @param {number} que_index 题目数组下标
     * @param {number} opt_index 题目选项数组下标
     */
    removeQueOption(que_index, opt_index) {
      let { option_arr } = this.questionForm
      let { question_item, answer } = option_arr[que_index];
      if (answer + '') {
        if (typeof answer == "number") {
          if (answer == opt_index) answer = "";
          if (answer > opt_index) answer = (answer - 1 < 0 && "") || answer - 1;
        } else {
          if (answer.indexOf(opt_index) != -1) {
            let a_index = answer.indexOf(opt_index);
            this.$_.pullAt(answer, a_index);
          }
          let newAnswer = [];
          answer.map((item) => {
            if (item > opt_index) newAnswer.push(item - 1);
            else newAnswer.push(item);
          });
          answer = [...newAnswer];
        }
      }
      this.$_.pullAt(question_item, opt_index);
      option_arr[que_index] = { ...option_arr[que_index], question_item: [...question_item], answer };
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 关闭弹窗
     */
    hideDialog() {
      this.questionForm = { tetea_pra_ismakeup: 20, tetea_pra_title: '', option_arr: [{}] };
      this.$refs.questionForm.clearValidate();
      this.questionDialog = false;
      this.checkList = []
    },
    /** 新增试题 */
    addQuestion() {
      let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
      this.$refs.questionForm.validate(async (valid) => {
        if (valid) {
          let option_arr = this.$_.cloneDeep(this.questionForm.option_arr);
          /* 遍历所有题目,设置章节id */
          option_arr.map(que => {
            let { question_item } = que;
            // 设置题目类型中文
            que.syque_typ_name=this.getSyque_typ_name(que.syque_typ_id)
            /* 选择题,将正确答案赋值到选项 */
            if (this.isChoice(que.syque_typ_id)) {
              if (question_item.length > 0) {
                question_item.map(item => item.tetea_pra_ite_isanswer = 20)
                if (typeof que.answer == 'number') {
                  question_item[que.answer].tetea_pra_ite_isanswer = 10;
                  // 设置为ABCD的答案
                  que.answer=letter[que.answer];
                }
              }
            }
            if (question_item.length > 0) {
              question_item.map((opt, opt_index) => {
                opt.teque_ite_code = changeEnChart(opt_index);
              })
            }
            // delete que.chapter;
            que = { ...que, question_item: [...question_item] };
          })
          let params = {
            ...this.questionForm,
            option_arr: [...option_arr]
          };
          let newQuestions = {
            tetea_pra_title: params.tetea_pra_title,
          }
          //是否是组合题 
          if (params.tetea_pra_ismakeup == 10) {
            newQuestions.syque_typ_id = 14
            newQuestions.option_arr = params.option_arr
            newQuestions.tetea_pra_ismakeup = params.tetea_pra_ismakeup
          } else {
            params.option_arr[0].question_item.map((item,index)=>{
              if(Array.isArray(params.option_arr[0].answer)){
                if(params.option_arr[0].answer.includes(index)){
                  item.tetea_pra_ite_isanswer=10
                }
              }
            })
            newQuestions.syque_typ_id = params.option_arr[0].syque_typ_id
            newQuestions.syque_typ_name = this.getSyque_typ_name(params.option_arr[0].syque_typ_id)
            newQuestions.question_item = params.option_arr[0].question_item
            newQuestions.answer = params.option_arr[0].answer
            newQuestions.tetea_pra_difficulty = params.option_arr[0].tetea_pra_difficulty
            newQuestions.tetea_pra_analysis = params.option_arr[0].tetea_pra_analysis
            newQuestions.tetea_kno_id = params.option_arr[0].tetea_kno_id
            newQuestions.tetea_pra_ismakeup = params.tetea_pra_ismakeup
          }
          //将题塞入数据
          let indexs = this.hierarchy.toString().split('-')
          //普通一层的
          if (indexs.length == 1) {
            this.questionDoc.splice(parseInt(indexs[0]), 0, newQuestions);
          } else if (params.tetea_pra_ismakeup == 10) {
            this.$message({
              type: "info",
              duration: 1500,
              message: "二级不可再添加组合题目",
            });
          } else {
            this.questionDoc[parseInt(indexs[0])].option_arr.splice(parseInt(indexs[1]), 0, newQuestions);
          }
          // 初始化
          this.questionForm = {
            tetea_pra_ismakeup: 20,
            tetea_pra_analysis: '',
            tetea_pra_title: '',
            option_arr: [{}],
          }
          this.questionDialog = false;
          this.$refs.questionForm.clearValidate();
          this.checkList = []
        }
      })
    },
    //根据类型id返回题目类型中文
    getSyque_typ_name(syque_typ_id) {
      return (this.questionType.find(obj => obj.syque_typ_id === syque_typ_id)).syque_typ_name
    },
    // 添加试题
    addOperation(hierarchy) {
      // 清除拖拽框
      this.closeDragFrame()
      this.hierarchy = hierarchy
      this.questionDialog = true
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog.is-fullscreen {
    margin: 0 !important;
    width: 100% !important;
  }

  .el-dialog__header {
    height: 40px;
  }

  .el-dialog__body {
    height: calc(100% - 60px);
    display: block;
    overflow: auto;
  }

  .el-dialog__title {
    line-height: 40px;
  }

  .el-dialog__headerbtn {
    top: 7px;
  }

}

.questionDoc {
  width: 100%;
  height: 100%;
  display: block;
  padding: 20px 0 0;
  box-sizing: border-box;

  .leftview {
    width: 49%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-right: 2%;
    height: 100%;
    overflow: auto;
    float: left;

    .blankDrawingBoard {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      background-color: #FFFFFF;
    }

    .drawingBoard {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      background-color: #999999;

      .movePaper {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.05);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        transform: rotate(45deg);

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }

      .testPaper {
        box-shadow: inset 0 0 1px 1px #000000;
        border-radius: 10px;
        position: absolute;
        left: 0;
        width: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        user-select: none;
        background-repeat: no-repeat;
        transition: all 0s !important;
        cursor: crosshair;

        &.all-scroll {
          cursor: all-scroll;
        }

        &.stepBack {
          opacity: 0.6;
        }
      }

      .dragFrame {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        background-repeat: no-repeat;
        transition: all 0s !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: -2px;
          top: -2px;
          width: 100%;
          height: 100%;
          border: 2px solid #FF0000;
        }

        .operating {
          position: absolute;
          z-index: 10;
          display: flex;
          height: 46px;
          background-color: #FFFFFF;
          border-radius: 5px;
          margin-top: 10px;
          box-shadow: 0 2px 5px #b1b1b1;
          .independent{
            width: 46px;
            height: 46px;
            position: relative;
            display: inline-block;
            &:hover {
              background-color: #EEEEEE;
              cursor: pointer;
              .topicon{
                display: inline-block;
              }
            }
          }
          .introduce{
            width: 46px;
            height: 46px;
            text-align: center;
            line-height: 46px;
            border-radius: 5px;
            font-size: 24px;
            &.disabled {
              background-color: #dfdfdf;
              cursor: not-allowed !important;
            }
          }
          .topicon{
            position: absolute;
            left: -17px;
            display: flex;
            height: 40px;
            top: -40px;
            width: 80px;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 7px;
            display: none;

            .iconfont{
              color: #FFFFFf;
              width: 40px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              font-size: 20px;
              border-radius: 7px;
              float: left;
              &:hover {
                background-color: #000000;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .allquestion {
    width: 49%;
    background-color: #ffffff;
    border-radius: 10px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 44px;

    .option {
      background-color: #FFFFFF;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .upimg {
        height: 32px;
        overflow: hidden;
        margin: 0 10px;

        ::v-deep {
          .el-upload--text {
            height: 32px;
          }
        }
      }

      ::v-deep {
        .el-switch__core {
          width: 50px !important;
          height: 26px;
        }

        .el-switch__core::after {
          width: 18px;
          height: 18px;
          margin-top: 2px;
        }

        .el-switch.is-checked .el-switch__core::after {
          margin-left: -20px;
        }
      }
    }

    .list {
      height: 100%;
      overflow: auto;
      width: 100%;

      .child {
        margin-top: 20px;
      }

      .iconcheckbox {
        font-size: 24px;
        margin-right: 10px;
        color: #6c4ecb;
        cursor: pointer;
      }

      .item {
        border: 1px solid #DDDDDD;
        width: 100%;
        padding: 0 15px 15px;
        border-radius: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;

        &.isCheck {
          box-shadow: inset 0 0 0px 2px #6c4ecb;
        }

        &.optCheck {
          animation: optCheck-border-inset 0.5s infinite alternate;
        }

        .headline {
          border-bottom: 1px solid #DDDDDD;
          display: flex;
          align-items: center;
          height: 46px;

          .name {
            font-size: 16px;

            .bold {
              min-width: 56px;
              background: #6340c8;
              border-radius: 12px 0px 12px 12px;
              text-align: center;
              color: #fff;
              margin: 0 14px;
              box-sizing: border-box;
              padding: 0 9px;
              font-size: 12px;
              line-height: 20px;
              font-weight: 300;
              display: inline-block;
            }
          }

          .delete {
            margin-left: auto;
            width: 27px;
            line-height: 27px;
            background-color: #FF0000;
            color: #FFFFFF;
            border-radius: 50%;
            text-align: center;
            font-size: 14px;
            opacity: 0.5;

            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }
        }

        .realm {
          display: flex;
          align-items: baseline;
          padding: 5px 0;
          margin-top: 20px;
          position: relative;
          margin-bottom: 5px;

          .xing {
            color: #FF0000;
            position: absolute;
            left: -10px;
            top: 4px;
          }

          .title {
            color: #cccccc;
            font-size: 12px;
            margin-left: 5px;
          }

          .addoptin {
            margin-left: auto;
            color: #6340c8;
            font-size: 14px;

            i {
              margin-right: 5px;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .topic {
          width: 100%;
          display: flex;
          min-height: 30px;
          align-items: center;

          &.isCheck {
            box-shadow: 0 0 0px 2px #6c4ecb;
            border-radius: 5px;
          }

          &.optCheck {
            animation: optCheck-border 0.5s infinite alternate;
          }
        }

        .tinymce {
          flex: 1;
          min-height: 46px;
          line-height: 30px;
          border-radius: 10px;
          font-size: 14px;
          color: #191919;
          box-sizing: border-box;
        }

        .tinymceval {
          background-color: #F6F7FA;
          flex: 1;
          line-height: 22px;
          border-radius: 5px;
          padding: 5px 20px;
        }

        .answers {
          width: 100%;

          li {
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            line-height: 32px;

            &.isCheck {
              box-shadow: 0 0 0px 2px #6c4ecb;
              border-radius: 5px;
            }

            &.optCheck {
              animation: optCheck-border 0.5s infinite alternate;
            }

            .el-checkbox {
              padding-right: 15px;
              margin-top: 0px;
            }

            .el-radio {
              padding-right: 15px;
              margin-top: 5px;
            }

            ::v-deep .el-radio__label {
              padding-left: 0;
            }

            .title {
              color: #666666;
              font-size: 16px;
              margin-right: 5px;
            }

            .index {
              margin-right: 5px;
            }

            i {
              margin-left: 10px;
              width: 27px;
              line-height: 27px;
              background-color: #FF0000;
              color: #FFFFFF;
              border-radius: 50%;
              text-align: center;
              font-size: 14px;
              opacity: 0.5;

              &:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.richflex {
  flex: 1;
}

.addOperation {
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
}

// 新建试题
.create-question {
  box-sizing: border-box;
  padding: 28px 40px 40px;

  .create-form {
    &-wrapper {
      height: 500px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-right: 46px;
      overflow: hidden;
      overflow-y: auto;

      ::v-deep {
        .el-upload--text {
          width: auto;
        }
      }

      .img-uploader {
        min-width: 46px;
        height: 46px;
        margin-left: 12px;

        ::v-deep .el-upload--text {
          height: 46px;
        }


        .img-box {
          height: 46px;
          position: relative;

          img {
            height: 100%;
            border-radius: 10px;
          }

          .del {
            width: 24px;
            height: 24px;
            background: #fff;
            position: absolute;
            top: -12px;
            right: -8px;
            font-size: 32px;
            border-radius: 50%;
            color: #f8516d;
            @include flexBox(center);
          }
        }

        .upload-wrapper {
          width: 46px;
          height: 46px;
          background: #2ac293;
          border-radius: 10px;
          color: #fff;
          font-size: 30px;
          @include flexBox(center);
        }
      }

      .form-item {
        width: 100%;
        @include flexBox;

        .el-button {
          flex-shrink: 0;
          margin-left: 10px;
        }

        .el-form-item {
          flex-grow: 1;
        }
      }

      .question-data {

        ::v-deep .el-checkbox,
        .el-radio {
          &__inner {
            width: 20px;
            height: 20px;
          }

          &__label {
            padding-left: 8px;
            color: #2b2b2b;
          }
        }
      }

      .compose-group .form-item {
        align-items: center;

        .el-form-item {
          margin-bottom: 0;
        }
      }

      .question-options {
        margin-top: 5px;

        .option {
          width: 100%;
          @include flexBox;

          .el-form-item {
            margin-bottom: 0;
            flex-grow: 1;
          }

          &-list {
            width: 100%;
          }

          &-no {
            font-size: 14px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .el-input {
            flex-grow: 1;
          }

          .el-button {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }

        .el-checkbox,
        .el-radio {
          height: 46px;
          line-height: 46px;
          margin: 10px 0 4px 24px;

          &-group {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      .subtopic-data {
        box-sizing: border-box;
        padding-left: 82px;
      }
    }
  }
}

@keyframes optCheck-border {
  0% {
    box-shadow: 0 0 0px 3px #FF0000;
  }

  100% {
    box-shadow: 0 0 0px 3px #FFFFFF;
  }
}

@keyframes optCheck-border-inset {
  0% {
    box-shadow: inset 0 0 0px 3px #FF0000;
  }

  100% {
    box-shadow: inset 0 0 0px 3px #FFFFFF;
  }
}
</style>
